<template lang="pug">
.sign-up
    main(ref="main")
        main-banner(v-if="!embed" :config="config")

        .container.page-wrapper: .inner-wrapper(:class="{ 'signup-card': embed }")
            h3(v-if="embed") {{ $t('registration.register_to_event', [this.eventTitle]) }}
            event-card(v-if="!embed" :config="config")

            .event-card.editing-disabled(v-if="updateDisabled || restrictedUpdates")
                p {{ $t(`registration.form.${updateDisabled ? 'updates_not_allowed' : 'updates_restricted'}`) }}

            registration-forms(:config="config" :editing="editing" @form-change="onFormChange" :disabled="updateDisabled" :embed="embed")

            portal-target(name="sign-up-root")

            disclaimers(:requirements="config.event_details.requirements")
</template>
<script>
// Utils
import { get } from 'lodash';

// Components
import Disclaimers from '@/components/registration/Disclaimers.vue';
import EventCard from '@/components/registration/EventCard.vue';
import MainBanner from '@/components/layout/MainBanner.vue';
import RegistrationForms from '@/components/registration/RegistrationForms.vue';

export default {
    name: 'SignUp',

    components: {
        Disclaimers,
        EventCard,
        MainBanner,
        RegistrationForms
    },

    props: {
        config: {
            type: Object,
            required: true
        },

        session: {
            type: Object,
            required: true
        },

        embed: {
            type: Boolean,
            default: false
        },

        editing: {
            type: Boolean,
            default: false
        },

        eventTitle: {
            type: String
        }
    },

    data() {
        const settings = get(this.config, 'settings', {});
        const publicLogin = this.$services.publicLogin;
        const isPublicReg = publicLogin.isPublicReg(this.config);
        const updatingUser = publicLogin.isUpdating(this.config);

        const allowProfileUpdates = settings.allow_profile_updates !== false;
        const enablePrivateRegistration = settings.enable_private_registration !== false;
        // check only the case when reg is not public, because in public reg we only have profile updates
        const profileUpdatsOnly = !isPublicReg && allowProfileUpdates && !enablePrivateRegistration;
        const privateRegOnly = !isPublicReg && !allowProfileUpdates && enablePrivateRegistration;

        return {
            activationUrl: get(this.config, 'user.activationUrl'),
            restrictedUpdates: updatingUser && (profileUpdatsOnly || privateRegOnly),
            regDisabled: !publicLogin.canRegister(this.config),
            updateDisabled: updatingUser && !publicLogin.canUpdateRegistration(this.config)
        };
    },

    created() {
        const { publicLogin } = this.$services;
        const updatingUser = publicLogin.isUpdating(this.config);
        const userLimitReached = publicLogin.isUserLimmitReached(this.config);
        const isRetrievingReg = this.$route.query.retrieveReg === 'true';
        const goToReg = !isRetrievingReg && ((!updatingUser && this.regDisabled) || (updatingUser && this.updateDisabled) || userLimitReached);

        if (goToReg) {
            const route = this.embed ? 'registration-embed' : 'registration';
            this.$router.replace({ name: route });
            return;
        }

        if (this.activationUrl && !this.editing && !isRetrievingReg) {
            this.$router.replace({ name: 'registration-success' });
        }
    },

    metaInfo() {
        if (!this.embed) {
            return {
                bodyAttrs: {
                    class: ['alt']
                }
            }
        }
    },

    methods: {
        onFormChange() {
            this.$refs.main.scroll(0, 0);
        }
    }
};
</script>
