<template lang="pug">
.warning-wall(:class="{ embed }"): main: .container.page-wrapper
    .page
        .body
            .content(v-if="warning")
                .message(v-html="warning")

            .actions
                button.btn.btn-primary.btn-medium.important(@click="accept" :class="{'embed-btn': embed}") {{ $t('registration.actions.warning_wall_accept') }}
                a.btn.btn-medium.important(v-if="rejectHref" :href="rejectHref" rel="noopener noreferrer" :class="{'embed-btn': embed}") {{ $t('registration.actions.warning_wall_reject') }}
</template>
<script>
// Utils
import { get } from 'lodash';

export default {
    name: 'WarningWall',

    props: {
        config: {
            type: Object,
            required: true
        },

        session: {
            type: Object,
            required: true
        },

        embed: {
            type: Boolean,
            default: false,
        }
    },

    data() {
        const enWarning = get(this.config, 'event_details.warning_wall_config.content.en');
        const enRejectHref = get(this.config, 'event_details.warning_wall_config.redirect.en');
        const locale = this.$i18n.locale;

        return {
            warning: get(this.config, `event_details.warning_wall_config.content.${locale}`, enWarning),
            rejectHref: get(this.config, `event_details.warning_wall_config.redirect.${locale}`, enRejectHref)
        }
    },

    methods: {
        accept() {
            const { branding, id } = this.$route.params;
            this.$services.storage.setItemWithPrefix(`${branding}_${id}`, 'warning_wall_ack', 1);
            this.$emit('accepted');
        }
    }
};
</script>
