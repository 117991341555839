<template lang="pug">
.welcome-embed-card(v-if="enabled")
    h3 {{ $t('registration.welcome_back_user', [username]) }}

    template(v-if="showRsvp || canUpdate")
        p {{ $t('registration.review_profile.update_intro') }}
        button.btn.btn-primary.btn-medium.embed-btn(type="button" @click="submit") {{ $t('registration.review_profile.call_to_action') }}

    template(v-if="!showRsvp && !canUpdate")
        p {{ $t('registration.form.updates_not_allowed')}}
</template>
<script>
// Utils
import { get } from 'lodash';

export default {
    name: 'WelcomeEmbedCard',

    props: {
        config: {
            type: Object,
            required: true
        },
    },

    computed: {
        canUpdate() {
            return this.$services.publicLogin.canUpdateRegistration(this.config);
        },

        enabled() {
            const userLimmitReached = this.$services.publicLogin.isUserLimmitReached(this.config);
            const isUpdating = this.$services.publicLogin.isUpdating(this.config);
            return (this.canUpdate || isUpdating) && !userLimmitReached;
        },

        username() {
            return get(this.config, 'user.fname');
        }
    },

    methods: {
        submit() {
            this.$router.push({
                name: 'registration-form-embed'
            });
        },
    }
}
</script>
