<template lang="pug">
.event-card
    slot(name="title")
    .card-details-container
        .logo
            img(:src="icon" :alt="$t('event_details.title').toString()" height="64" width="64" @error="onIconLoadError")

        .details
            event-calendar-button(
                v-if="dates && showCalendarButton && !config.theme.hide_calendar"
                :event-details="config.event_details"
                :calendar="config.calendar")
            h1(v-if="mainTitle") {{ $t('event_details.title') }}
            h2(v-else) {{ $t('event_details.title') }}
            p.date(v-if="dates && !config.theme.hide_date_info")
                strong {{ dates.startDate }}
                |  {{ $t('registration.to_date') }}&nbsp;
                strong {{ dates.endDate }}
                |  {{ dates.timezone }}
</template>
<script>
// Utils
import { get } from 'lodash';

// Components
import EventCalendarButton from '@/components/EventCalendarButton.vue';

export default {
    name: 'EventCard',

    components: { EventCalendarButton },

    props: {
        config: {
            type: Object,
            required: true
        },

        showCalendarButton: {
            type: Boolean,
            default: true
        },

        mainTitle: {
            type: Boolean,
            default: true
        }
    },

    data() {
        const icon = this.$utils.assets.getEventAssetSrc('event-icon', this.config.event_details);
        return { icon };
    },

    computed: {
        /** @return {number} */
        startTime() {
            return get(this.config, 'event_details.start_time');
        },

        /** @return {object|null} */
        dates() {
            if (!this.startTime) return null;
            const endDate = get(this.config, 'event_details.end_time');
            const tz = get(this.config, 'event_details.timezone');
            const useEventTimezone = get(this.config, 'event_details.display_timezone') !== 'device';
            return this.$utils.time.formatEventDates(this.$i18n.locale, this.startTime, endDate, tz, null, useEventTimezone);
        }
    },

    methods: {
        onIconLoadError() {
            this.icon = this.$utils.assets.getStaticAssetSrc('workspace-icon.png');
        }
    }
};
</script>
