<template lang="pug">
component.registration(
    v-if="ready"
    :is="subpage"
    :key="subpage"
    :embed="embed"
    :event-title="eventTitle"
    :config="config"
    :session="session"
    :editing="editing"
    @updated="refreshData"
    @accepted="warningAccepted = true")
</template>
<script>
// Utils
import { get, set, omit } from 'lodash';

// Components
import Landing from '@/pages/registration/Landing.vue';
import LandingEmbed from '@/pages/registration/LandingEmbed.vue';
import End from '@/pages/registration/End.vue';
import EnterPin from '@/pages/registration/EnterPin.vue';
import EnterPinEmbed from '@/pages/registration/EnterPinEmbed.vue';
import SignUp from '@/pages/registration/SignUp.vue';
import Summary from '@/pages/registration/Summary.vue';
import WarningWall from '@/pages/registration/WarningWall.vue';

export default {
    name: 'Registration',

    components: {
        End,
        Landing,
        LandingEmbed,
        EnterPin,
        EnterPinEmbed,
        SignUp,
        Summary,
        WarningWall
    },

    props: {
        branding: {
            type: Object,
            required: true
        },

        session: {
            type: Object,
            required: true
        }
    },

    data() {
        return {
            themeAppended: false,
            faviconPath: null,
            config: null,
            editing: false,
            warningAccepted: true
        }
    },

    computed: {
        /** @returns {string} */
        subpage() {
            if (!this.warningAccepted) {
                return 'WarningWall';
            }

            return this.$route.meta.subpage;
        },

        /** @returns {boolean} */
        embed() {
            return this.$route.meta.embed || false;
        },

        /** @returns {boolean} */
        ready() {
            return this.config != null && this.themeAppended;
        },

        /** @returns {string} */
        eventTitle() {
            if (this.config.event_details?.title) {
                const titleObj = this.config.event_details.title;
                const title = titleObj[this.$i18n.locale] || titleObj['en'];
                return this.$i18n.te('event_details.title') ? this.$i18n.t('event_details.title') : title;
            }
        }
    },

    async created() {
        await this.loadConfig(true);
        if (!this.config) {
            return;
        }

        this.faviconPath = this.$utils.assets.getEventAssetSrc('event-icon', this.config.event_details);
        const { title } = this.config.event_details;

        if (title) {
            Object.assign(this.config.i18n, { event_details: { title } });
        }

        this.$updateMessages(this.config.i18n, this.config.event_details.languages_with_translations, this.config.user?.fp_locale);

        this.checkWarning();
    },

    methods: {
        /** @returns {string} */
        getTheme() {
            return this.$utils.theme.themeToCss(this.config.theme);
        },

        /**
         * Loads the configuration
         *
         * @param {boolean} redirectOnError whether to redirect the user to the root page in case of errors
         * @param {boolean} forceLogin whether to force a user login to gather user's info
         */
        async loadConfig(redirectOnError = false, forceLogin = false) {
            try {
                const { branding, id } = this.$route.params;
                const storedBust = this.$services.storage.getItemWithPrefix(`${branding}_${id}`, 'bust_cache');
                const templateEid = branding === 'templates_registration' ? id : undefined;
                const bustCacheParam = Object.keys(this.$route.query || {}).includes('bustCache');
                const bustCache =  Boolean(storedBust) || bustCacheParam;
                const config = await this.$services.branding.getRegistrationConfig(branding, id, this.session, templateEid, bustCache);
                const { _id: eid } = config.event_details;

                // remove "bustCache" query param (keeping others if there are any)
                if (bustCache) {
                    console.log('[Registration] Cache busted');
                    if (this.$route.query.bustCache) {
                        const query =  omit(this.$route.query, ['bustCache']);
                        this.$router.replace({ path: window.location.pathname, query });
                    }
                    this.$services.storage.setItemWithPrefix(`${branding}_${id}`, 'bust_cache', '1');
                }

                if (bustCacheParam) {
                    // When coming from backstage (bustCacheParam), remove the warning wall ack
                    // so that event producers can see the warning wall again.
                    this.$services.storage.removeItemWithPrefix(`${branding}_${id}`, 'warning_wall_ack');
                }

                if (this.session.token || forceLogin) {
                    const { user, is_sso } = await this.$services.session.getCurrentUser(eid, this.session.token);
                    if (!get(config, 'event_details.invitation.token')) {
                        set(config, 'event_details.invitation.token', this.session.token);
                    }
                    config.user = user || { };
                    this.editing = this.$services.publicLogin.canUpdateRegistration(config);
                    if (this.$services.publicLogin.isUpdating(config)) {
                        this.$services.publicLogin.disableNonUpdatableFields(config);
                    }
                    if (is_sso) {
                        this.$services.publicLogin.disableEmailFields(config);
                    }
                }

                this.config = config;

            } catch (error) {
                const statusText = get(error, 'response.statusText');
                const message = get(error, 'response.data.error', statusText || error.message);

                console.error('[Registration] An error occurred:', message);

                if (redirectOnError) {
                    this.$router.push({ name: 'root', replace: true });
                }
            }
        },

        refreshData() {
            this.loadConfig(false, true);
        },

        /**
         * Checks if the event has a warning wall and if the user has accepted it.
         * If not, it will display the warning wall to the user.
         */
        checkWarning() {
            if (this.config.event_details?.warning_wall_config?.enabled) {
                const { branding, id } = this.$route.params;
                const warningAccepted = this.$services.storage.getItemWithPrefix(`${branding}_${id}`, 'warning_wall_ack');
                this.warningAccepted = Boolean(warningAccepted);
            }
        }
    },

    metaInfo() {
        const meta = {};

        if (this.config) {
            meta['style'] = [{ cssText: this.getTheme(), type: 'text/css', id: '_theme_', callback: () => this.themeAppended = true}];

            if (this.eventTitle) {
                meta['title'] = this.eventTitle;
                meta['titleTemplate'] = null;
            }
        }

        meta['link'] = [
            { vmid: 'main-style', rel: 'stylesheet', href: `${window.APP.assetsPath}/css/auth-v2${window.APP.assetSuffix}.css` },
            { rel: 'icon', href: this.faviconPath || this.$utils.assets.getStaticAssetSrc('favicon.svg'), vmid: 'favicon' }
        ];

        return meta;
    }
};
</script>
