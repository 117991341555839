<template lang="pug">
header.main(v-if="showHeader"): .container
    aside.actions(:class="{ 'has-language-selector': showLanguageSelectorButton }")
        event-calendar-button(
            v-if="showCalendarButton"
            :event-details="config.event_details"
            :calendar="config.calendar")
            | {{ $t('registration.actions.add_to_cal') }}

        login-button.btn-primary.with-access(v-if="showGoToEventButton" :config="config")

        language-selector-button(
            v-if="showLanguageSelectorButton"
            :language-codes="config.event_details.languages_with_translations"
        )

        login-button.btn-primary.without-access(v-if="showLoginButton" :config="config")

        template(v-if="showLogoutButton")
            span.separator(v-if="showLogoutSeparator")
            logout-button

    slot
</template>
<script>
// Utils
import { get } from 'lodash';

// Components
import EventCalendarButton from '@/components/EventCalendarButton.vue';
import LanguageSelectorButton from '@/components/registration/LanguageSelectorButton.vue';
import LoginButton from '@/components/registration/LoginButton.vue';
import LogoutButton from '@/components/registration/LogoutButton.vue';

export default {
    name: 'MainHeader',

    props: {
        config: {
            type: Object,
            required: true
        },

        session: {
            type: Object,
            required: true
        },

        hasSectionAnchors: {
            type: Boolean,
            default: false
        }
    },

    components: {
        EventCalendarButton,
        LanguageSelectorButton,
        LoginButton,
        LogoutButton
    },

    data() {
        return {
            showHeader: true,
            showLogoutButton: false,
            showLoginButton: false,
            showLanguageSelectorButton: false,
            showGoToEventButton: false,
        }
    },

    computed: {
        showCalendarButton() {
            if (
                this.showGoToEventButton && this.showLanguageSelectorButton
                || !this.config.event_details.start_time
            ) {
                return false;
            }

            return !get(this.config, 'theme.hide_calendar');
        },

        showLogoutSeparator() {
            if (!this.showLogoutButton) {
                return false;
            }

            return this.showLoginButton
                || this.showCalendarButton
                || this.showGoToEventButton;
        }
    },

    watch: {
        config: {
            immediate: true,
            handler() {
                this.showLanguageSelectorButton = this.$services.publicLogin.isLanguageSelectorVisible(this.config);

                this.showLogoutButton = this.$services.publicLogin.isAuthenticated(this.config)
                    && !this.$services.publicLogin.isRSVPPendingUser(this.config);

                this.showLoginButton = this.$services.publicLogin.canLogin(this.config)
                    && !this.config?.user?.activationUrl;

                this.showGoToEventButton = this.$services.publicLogin.canLogin(this.config)
                    && !!this.config?.user?.activationUrl;

                this.showHeader = this.hasSectionAnchors
                    || this.showLoginButton
                    || this.showLogoutButton
                    || this.showCalendarButton
                    || this.showLanguageSelectorButton
                    || this.showGoToEventButton;
            }
        }
    }
};
</script>
