<template lang="pug">
.sign-up
    main(ref="main")
        .container
            pin-input(:config="config" :embed="true")
</template>
<script>

// Components
import PinInput from '@/components/registration/PinInput.vue';

export default {
    name: 'EnterPinEmbed',

    components: {
        PinInput
    },

    props: {
        config: {
            type: Object,
            required: true
        }
    },

    data() {
        return {};
    },

    methods: {}
};
</script>
