<template lang="pug">
calendar-button(v-if="enabled" :g-cal-url="gCalUrl" :i-cal-url="iCalUrl" :important="important")
    slot
</template>
<script>
// Components
import CalendarButton from '@/components/CalendarButton.vue';

export default {
    name: 'EventCalendarButton',

    components: { CalendarButton },

    props: {
        eventDetails: {
            type: Object,
            required: true
        },

        calendar: {
            type: Object,
            default: () => ({})
        },

        important: {
            type: Boolean,
            default: false
        }
    },

    data() {
        const { _id, title, path_name, timezone, start_time, end_time, branded_app } = this.eventDetails;
        const name = title[this.$i18n.locale] || String(this.$i18n.t('event_details.title'));
        let gCalUrl = this.calendar.google_calendar_link;

        if (!this.calendar.google_calendar_link) {
            const iCalendar = this.calendar.i_calendar || {};
            gCalUrl = this.$utils.url.getGoogleCalendarUrl({
                _id,
                name: iCalendar.title || name,
                start: start_time,
                end: end_time,
                location: iCalendar.location,
                content: iCalendar.description || this.$utils.url.getRegistrationLink(branded_app, path_name)
            }, timezone);
        }

        const iCalUrl = this.$utils.url.getCalendarICS(_id);

        return { enabled: start_time && end_time, gCalUrl, iCalUrl };
    }
}
</script>
