<template lang="pug">
.support-warning(v-if="showUnsupportedBrowserWarning")
    i.support-warning-icon.icon-warning-lozenge
    .support-warning-text
        h5.support-warning-title {{ $t(isSafari ? 'registration.support_warning.misconfigured_safari.title' : 'registration.support_warning.unsupported_browser.title') }}
        .support-warning-description {{ $t(isSafari ? 'registration.support_warning.misconfigured_safari.description' : 'registration.support_warning.unsupported_browser.description') }}
</template>
<script>
export default {
    name: 'SupportWarning',

    data() {
        return {
            isSafari: this.$utils.browsers.isSafari(),
            showUnsupportedBrowserWarning: navigator.cookieEnabled === false,
        };
    }
};
</script>
