// Utils
import { get } from 'lodash';

// Classes
import BaseService from './base-service';

// Constants
import { API_BASE_PATH } from '@/libs/utils/constants';

/**
 * Public login registration API endpoint
 * @constant {String} PUBLIC_LOGIN_API_ENDPOINT
 */
const PUBLIC_LOGIN_API_ENDPOINT = `${API_BASE_PATH}/public-login/branding/{{branding}}/eid/{{eventId}}/account`;

/**
 * Get the login action API endpoint
 * @constant {String} LOGIN_ACTION_API_ENDPOINT
 */
const LOGIN_ACTION_API_ENDPOINT = `${API_BASE_PATH}/public-login/eid/{{eventId}}/login-action`;

/**
 * Validate pin code API endpoint
 * @constant {String} VALIDATE_PIN_CODE_API_ENDPOINT
 */
const VALIDATE_PIN_CODE_API_ENDPOINT = `${API_BASE_PATH}/public-login/eid/{{eventId}}/validate-pin`;

/**
 * Email verification API endpoint
 * @constant {String} EMAIL_VERIFICATION_API_ENDPOINT
 */
const EMAIL_VERIFICATION_API_ENDPOINT = `${API_BASE_PATH}/public-login/eid/{{eventId}}/free-email-domain`;

/**
 * Start payment step endpoint
 * @constant {String} PAYMENT_START_API_ENDPOINT
 */
const PAYMENT_START_API_ENDPOINT = `${API_BASE_PATH}/public-login/eid/{{eventId}}/payment-start`;

/**
 * Provides methods necessary for managing public login
 * user's registration.
 *
 * @example
 *
 * import PublicLoginService from 'libs/services/session';
 * ...
 * const publicLogin = new PublicLoginService();
 */
export default class PublicLoginService extends BaseService {

    /**
     * Requests the system to perform a login.
     *
     * @param {string} branding the branding of the event
     * @param {string} eventId the ID of the event to request the login for
     * @param {string} email the user's email
     * @param {string} [fname] the user's first name. It is necessary if the event has allow_non_unique_email flag set to true.
     * @param {string} [lname] the user's last name. It is necessary if the event has allow_non_unique_email flag set to true.
     *
     * @returns {Promise<boolean>} true if the request succeeded
     */
    async requestLogin(branding, eventId, email, fname, lname) {
        const url = this.buildUrl(PUBLIC_LOGIN_API_ENDPOINT, { branding, eventId });
        const { status } = await this.put(url, { email, fname, lname });

        return status === 204;
    }

    /**
     * Given an event ID and a form payload, this method tries to
     * perform the user's registration.
     *
     * @param {string} branding the branding of the event
     * @param {string} eventId the ID of the event to register the account for
     * @param {object} form the form payload
     *
     * @returns {Promise<{ action: string, sendingEmail: boolean, redirect: string }>} the server response
     */
    async registerAccount(branding, eventId, form) {
        const url = this.buildUrl(PUBLIC_LOGIN_API_ENDPOINT, { branding, eventId });
        const { data } = await this.post(url, form);

        return data;
    }

    /**
     * Get the action to take after login depending on the email existence
     * @param {string} eventId the ID of the event
     * @param {string} email the email to check
     * @param {string} branding the event branding
     * @returns {Promise<string>} the action to take
     */
    async getLoginAction(eventId, email, branding) {
        const url = this.buildUrl(LOGIN_ACTION_API_ENDPOINT, { eventId });
        const { data } = await this.post(url, { email, branding });
        return data.action;
    }

    /**
     * Validates the pin code
     * @param {string} eventId the ID of the event
     * @param {string} email the email to check
     * @param {string} pin the pin code to validate
     * @returns {Promise<string|undefined>} the registration url
     */
    async validatePin(eventId, email, pin) {
        try {
            const url = this.buildUrl(VALIDATE_PIN_CODE_API_ENDPOINT, { eventId });
            const { data } = await this.post(url, { email, pin });
            return data?.url;
        } catch {
            return undefined;
        }

    }

    /**
     * Checks if the domain of an email is among the free email providers
     *
     * @param {string} eventId the ID of the event
     * @param {string} domain the email domain to check
     *
     * @returns {Promise<Boolean>}
     */
    async verifyFreeEmailDomain(eventId, domain) {
        const url = this.buildUrl(EMAIL_VERIFICATION_API_ENDPOINT, { eventId });
        const { data } = await this.post(url, { domain });
        return data;
    }

    /**
     * Checks whether the user can update the profile
     *
     * @param {object} config the reg configuration
     *
     * @returns {boolean}
     */
    canUpdateRegistration(config) {
        const isReturningUser = this.isUpdating(config);
        const isProfileUpdateAllowed = Boolean(get(config, 'settings.allow_profile_updates'));
        const isPublicReg = this.isPublicReg(config);
        const isRsvpChangeAllowed = !isPublicReg && Boolean(get(config, 'settings.enable_private_registration'));
        const isUpdateAllowed = isProfileUpdateAllowed || isRsvpChangeAllowed;
        const userIsPending = get(config, 'user.fp_rsvp_status') === 'pending';

        if (userIsPending && !isPublicReg && !isRsvpChangeAllowed && isProfileUpdateAllowed) {
            return false;
        }

        return isReturningUser && isUpdateAllowed;
    }

    /**
     * Checks whether the user limit is reached
     *
     * @param {object} config the reg configuration
     *
     * @returns {boolean}
     */
    isUserLimmitReached(config) {
        let userLimit = 0, userCount = -1;

        if (this.isRSVPPendingUser(config)) {
            userLimit = get(config, 'settings.rsvp_accepted_user_limit', 0);
            userCount = get(config, 'event_details.rsvp_accepted_user_count', -1);
        } else if (!this.isUpdating(config) && !get(config, 'settings.self_registered_waiting_list')) {
            userLimit = get(config, 'settings.self_registered_user_limit', 0);
            userCount = get(config, 'event_details.self_registered_user_count', -1);
        }
        return userCount >= userLimit;
    }

    /**
     * Checks whether a waiting list is used if the user limits is reached
     *
     * @param {object} config the reg configuration
     *
     * @returns {boolean}
     */
    displayWaitingList(config) {
        const userLimit = get(config, 'settings.self_registered_user_limit', 0);
        const userCount = get(config, 'event_details.self_registered_user_count', -1);
        return userCount >= userLimit && get(config, 'settings.self_registered_waiting_list');
    }

    /**
     * Checks whether the user can register in a public event
     *
     * @param {object} config the reg configuration
     *
     * @returns {boolean}
     */
    canRegister(config) {
        const isReturningUser = this.isUpdating(config);
        const isRegisterAllowed = !get(config, 'settings.disable_registration');
        const isPrivate = this.isPrivateReg(config);
        const usePinCode = Boolean(config.event_details.use_registration_pin_code);
        return !isReturningUser && isRegisterAllowed && !(isPrivate && !usePinCode);
    }

    /**
     * Checks whether the user is autenticated
     *
     * @param {object} config the reg configuration
     *
     * @returns {boolean}
     */
    isAuthenticated(config) {
        return Boolean(get(config, 'user._authenticated'));
    }

    /**
     * Checks whether the user is updating his profile
     *
     * @param {object} config the reg configuration
     *
     * @returns {boolean}
     */
    isUpdating(config) {
        return Boolean(get(config, 'user._returningUser'));
    }

    /**
     * Checks whether the user is in RSVP pending state
     *
     * @param {object} config the reg configuration
     *
     * @returns {boolean}
     */
    isRSVPPendingUser(config) {
        return get(config, 'user.fp_rsvp_status') === 'pending' && !this.isPublicReg(config);
    }

    /**
     * Checks whether the user is autenticated
     *
     * @param {object} config the reg configuration
     *
     * @returns {boolean}
     */
    canLogin(config) {
        const eventInProduction = get(config, 'event_details.stage') === 'production' || get(config, 'event_details.is_webinar');
        const loginButtonEnabled = !get(config, 'settings.disable_login');
        const hasActivationUrl = Boolean(get(config, 'user.activationUrl'));
        const canRequestLogin = !this.isAuthenticated(config);
        return eventInProduction && loginButtonEnabled && (hasActivationUrl || canRequestLogin);
    }

    /**
     * Checks whether the config has RSVP set up
     *
     * @param {object} config the reg configuration
     *
     * @returns {boolean}
     */
    hasRsvp(config) {
        const accessType = this.getAccessType(config);
        const hasRsvp = accessType !== 'public';
        const privateEnabled = get(config, 'settings.enable_private_registration', false);
        return privateEnabled && hasRsvp;
    }

    /**
     * Gets the registration access type
     *
     * @param {object} config the reg configuration
     *
     * @returns {'public'|'private'|'hybrid'}
     */
    getAccessType(config) {
        return get(config, 'settings.access_type', 'public');
    }

    /**
     * Checks if the current reg is public
     *
     * @param {object} config the reg configuration
     *
     * @returns {boolean}
     */
    isPublicReg(config) {
        return this.getAccessType(config) === 'public';
    }

    /**
     * Checks if the current reg is private
     *
     * @param {object} config the reg configuration
     *
     * @returns {boolean}
     */
    isPrivateReg(config) {
        return this.getAccessType(config) === 'private';
    }

    /**
     * Disables fields that cannot be updated in given config
     *
     * @param {object} config
     */
    disableNonUpdatableFields(config) {
        for (const formName of Object.keys(config.forms)) {
            const fields = get(config.forms, [ formName, 'fields' ], []);
            for (const field of fields) {
                field.disabled = field.disabled || field.disabled_on_update;
            }
        }
    }

    disableEmailFields(config) {
        for (const formName of Object.keys(config.forms)) {
            const fields = get(config.forms, [ formName, 'fields' ], []);
            for (const field of fields) {
                if (field.kind === 'email') {
                    field.disabled = true;
                }
            }
        }
    }

    /**
     * Checks if the email authentication is disabled
     *
     * @param {object} config
     * @returns {boolean}
     */
    isEmailAuthDisabled(config) {
        return get(config, 'settings.disable_email_login');
    }

    /**
     * Gets the enabled SSO providers
     *
     * @param {object} config
     * @returns {object[]}
     */
    getSsoProviders(config) {
        const ssoEnabled = get(config, 'settings.enable_sso_login');
        return ssoEnabled ? get(config, 'settings.public_ssos', []) : [];
    }

    /**
     * @param {object} config
     * @returns {boolean}
     */
    isLanguageSelectorVisible(config) {
        if (!get(config, 'event_details.show_language_selector')) {
            return false;
        }

        return get(config, 'event_details.languages_with_translations.length', 0) >= 2;
    }

    /**
     * Given an event ID and customer email, we communicate to the backend that a payment is starting
     *
     * @param {string} eventId the ID of the event to register the account for
     * @param {string} email the customer email
     *
     */
    async triggerPaymentStep(eventId, email) {
        const url = this.buildUrl(PAYMENT_START_API_ENDPOINT, { eventId });
        const { data } = await this.post(url, { email });

        return data;
    }
}
