<template lang="pug">
validation-observer(v-if="ready" tag="form" ref="form" @submit.prevent="save")
    h3(v-if="title") {{ title }}

    compliance(type="header" :config="config")

    fieldset
        template(v-for="(control, index) in fields")

            paragraph(v-if="control.type === 'text'" :text="control.text" :key="`${name}-${getControlName(control)}`")

            validation-provider(v-else-if="control.type === 'sessions'"
                slim
                mode="passive"
                v-slot="{ errors: e }"
                :rules="$utils.controls.getControlValidators(control, eventId, settings, editing)"
                :key="`${name}-${getControlName(control)}-${index}`"
                :name="`${control.name}-${index}`")

                sessions-registration(
                    :control="control"
                    :config="config"
                    :errors="e"
                    :name="control.name"
                    :value="innerValue[control.name]"
                    :orchestrator="orchestrators['sessions-registration']"
                    :disabled="disabled"
                    :start-expanded="!orchestrators['sessions-registration'].hasFormMultipleBlocks(name)"
                    @change="(value) => setControlSessionsValue(control, value)")

            validation-provider(v-else slim
                :rules="$utils.controls.getControlValidators(control, eventId, settings, editing)"
                :mode="$utils.controls.getValidatorsMode(control)"
                v-slot="{ errors: e }"
                :name="getControlName(control)"
                :key="`${name}-${getControlName(control)}`")

                meta-control(
                    :control="control"
                    :errors="e"
                    :value="innerValue[getControlName(control)]"
                    :disabled="disabled"
                    @input="(value) => setControlValue(control, value)")

        compliance(type="footer" :config="config")

    portal(v-if="portal" :to="portal")
        .alert.alert-error(v-if="errorMessage")
            p
                i.icon-warning-fill
                | {{ errorMessage }}

        .form-actions
            button.btn.btn-medium.back(v-if="showPrevButton" type="button" @click="$emit('prev')" :class="{'embed-btn': embed}")
                | {{ $t('registration.actions.previous_section') }}
            button.btn.btn-primary.btn-medium(@click="save" :disabled="actionDisabled" :class="{'embed-btn': embed}")
                | {{ $t(actionLabel) }}

    //- The hidden class will be added so the submit button
    //- will still be present for handling the
    //- keyboard enter event: it will try to submit the form
    //- in case the UI visible submit button does not live within
    //- the FORM tag.
    div(:class="{hidden: portal}")
        button.btn.btn-primary.btn-medium(@click="save" :disabled="actionDisabled" :class="{'embed-btn': embed}")
            | {{ $t(actionLabel) }}
</template>
<script>
// Utils
import { cloneDeep, isEmpty, isObject, isString } from 'lodash';

// Components
import MetaControl from '@/components/form-elements/MetaControl.vue';
import Paragraph from '@/components/form-elements/Paragraph.vue';
import SessionsRegistration from '@/components/form-elements/SessionsRegistration.vue';
import Compliance from '@/components/Compliance.vue';

export default {
    name: 'GenericForm',

    components: { Compliance, MetaControl, Paragraph, SessionsRegistration },

    props: {
        config: {
            type: Object,
            required: true
        },

        form: {
            type: Object,
            required: true
        },

        orchestrators: {
            type: Object,
            required: true
        },

        settings: {
            type: Object,
            required: true
        },

        eventId: {
            type: String,
            required: true
        },

        name: {
            type: String,
            required: true
        },

        disabled: {
            type: Boolean,
            default: false
        },

        value: {
            type: Object,
            default: () => ({})
        },

        actionLabel: {
            type: String,
            default: 'login.actions.submit'
        },

        portal: {
            type: String,
            default: null
        },

        errors: {
            type: [Object, String],
            default: () => {}
        },

        showPrevButton: {
            type: Boolean,
            default: false
        },

        title: {
            type: String,
            default: null
        },

        editing: {
            type: Boolean,
            default: false
        },

        disableAction: {
            type: Boolean,
            default: null
        },

        embed: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            innerValue: null,
            fields: this.form.fields,
            ready: false,
            actionDisabled: this.disableAction === null ? this.disabled : this.disableAction,
            errorMessage: null,
            manualErrors: {}
        };
    },

    watch: {
        errors: {
            immediate: true,
            handler(value) {
                if (!isEmpty(value)) {
                    if (isObject(value) && this.$refs.form) {
                        this.$refs.form.setErrors(value);
                    }

                    this.errorMessage = this.getErrorMessage();
                }
            }
        },

        value: {
            deep: true,
            immediate: true,
            handler(value) {
                this.innerValue = cloneDeep(value);
            }
        },
    },

    async created() {
        this.paramsToFields();
        this.prefillControls();
        this.ensureFpRsvpStatusIsSetIfRequired();
        await this.prepareLegals();
        this.ready = true;
    },

    methods: {
        /**
         * Populates fields from URL params
         */
        paramsToFields() {
            const params = this.$route.query;

            for (const param of Object.keys(params)) {
                // @ts-ignore
                const field = this.fields.find(f => f.name === param && !f.disabled);
                if (field) {
                    const value = this.$utils.strings.sanitizeParam(field, params[param], true);
                    if (value) {
                        this.setControlValue(field, value);
                    }
                }
            }
        },

        /**
         * Reconciles fields with the form values
         */
         prefillControls() {
            for (const field of this.fields) {
                if (field.hasOwnProperty('value') && typeof field.value !== 'undefined') {
                    this.setControlValue(field, field.value);
                }
            }
        },

        /**
         * Prepares labels for legal documents
         */
        async prepareLegals() {
            const legals = this.fields.filter(f => f.type === 'legal-document');

            for (const legal of legals) {
                const requirements = await this.$services.event.getRequirementsDocById(legal.id);
                let isLegacyBe = true;

                if (isEmpty(requirements)) {
                    // Remove all legal docs fields that do not have requirements
                    this.fields = this.fields.filter(f => f.id !== legal.id);
                } else if (requirements[0].hasOwnProperty('i18n')) {
                    isLegacyBe = false;
                }

                legal.requirements = requirements;

                let i18nLabels;
                if (isLegacyBe) {
                    i18nLabels = this.$utils.locales.getLegacyLegalMessages(requirements, legal.required, this.$i18n.locale, this.$i18n.messages);
                } else {
                    i18nLabels = this.$utils.locales.getLegalMessages(requirements, legal.required);
                }

                for (const [locale, labels] of Object.entries(i18nLabels)) {
                    this.$i18n.mergeLocaleMessage(locale, labels);
                }
            }
        },

        /**
         * Sets the value for the given field control
         *
         * @param {object} control
         * @param {any} value
         */
        setControlValue(control, value) {
            this.$set(this.innerValue, this.getControlName(control), value);
            this.$emit('input', this.innerValue);
        },

        /**
         * Sets the value of a control in the form's control sessions.
         *
         * @param {Object} control - The control object.
         * @param {Object} payload - The payload object.
         * @param {Object} payload.register - The register IDs.
         * @param {Object} payload.unregister - The unregister IDs.
         */
        setControlSessionsValue(control, { register, unregister }) {
            this.$set(this.innerValue, '_unregister', unregister || []);
            this.setControlValue(control, register);
        },

        /**
         * Validates and saves the form
         */
        async save() {
            let valid = await this.$refs.form.validate();

            if (valid) {
                const [fieldName] = this.getSessionsRegFieldNames();
                if (fieldName) {
                    const regorchestrator = this.orchestrators['sessions-registration'];
                    valid = await regorchestrator.isSelectionValid();
                    if (!valid) {
                        this.$set(this.manualErrors, fieldName, ['registration.form.messages.selection_outdated']);
                    }
                }
            }

            if (valid) {
                this.$emit('change', this.innerValue);
            } else {
                this.errorMessage = this.getErrorMessage();
            }
        },

        /**
         * Returns the proper unique id of the control
         * @param {object} control
         * @returns {string}
         */
        getControlName(control) {
            return this.$utils.controls.getControlName(control);
        },

        /**
         * Returns the error message for the form element.
         *
         * @returns {string} The error message.
         */
        getErrorMessage() {
            const errors = this.getErrors();
            if (isEmpty(errors)) {
                return null;
            }

            let message = this.$t('registration.form.messages.generic_error');
            if (isString(this.errors) && this.$te(this.errors)) {
                message = this.$t(this.errors);
            }

            if (isObject(errors)) {
                const minSessionsNumber = this.settings.min_registered_sessions || 1;
                this.getSessionsRegFieldNames().forEach(fieldName => {
                    if (errors.hasOwnProperty(fieldName)) {
                        const [errorKey] = errors[fieldName] || [];
                        if (['not_min_registered_sessions', 'this field is required'].includes(errorKey)) {
                            message = this.$tc('registration.form.messages.not_min_registered_sessions', minSessionsNumber, [minSessionsNumber]);
                        } else if (errors[fieldName]?.length > 1) {
                            message = this.$t('registration.form.messages.some_errors_occurred');
                        } else if (this.$te(errorKey)) {
                            message = this.$t(errors[fieldName]);
                        }
                    }
                });

                // when a user does not have minimum sessions because no session field exists
                const isMinSessionsError = (errors.session_fp_ext_ids || []).includes('not_min_registered_sessions');
                if (isMinSessionsError && !this.getSessionsRegFieldNames().length) {
                    message = this.$tc('registration.form.messages.not_min_registered_sessions', minSessionsNumber, [minSessionsNumber]);
                }
            }

            return message;
        },

        /**
         * Retrieves the field names for the sessions registration form.
         *
         * @returns {string[]} An array of field names.
         */
        getSessionsRegFieldNames() {
            return this.fields
                .filter(f => f.type === 'sessions')
                .map(f => f.name);
        },

        /**
         * Retrieves the errors for the form.
         *
         * @returns {Array} An array of errors for the form.
         */
        getErrors() {
            if (!isEmpty(this.errors)) {
                return this.errors;
            }

            if (!isEmpty(this.manualErrors)) {
                return this.manualErrors;
            }

            return this.$refs.form?.errors;
        },

        /**
         * if fields include fp_rsvp_status and it is required
         * ensure the value is one of available values
         */
        ensureFpRsvpStatusIsSetIfRequired() {
            const fpStatusField = this.fields.find(d => d.name === 'fp_rsvp_status');
            if (!fpStatusField || fpStatusField.disabled || !fpStatusField.required) {
                return;
            }

            const userWritableValues = fpStatusField.kind_options?.user_writable || [];
            if (userWritableValues.length && !userWritableValues.includes(this.innerValue.fp_rsvp_status)) {
                this.setControlValue(fpStatusField, userWritableValues[0]);
            }
        }
    }
};
</script>
