/**
 * Check if the current browser is Safari.
 * Note that this might not be perfect and could evolve in the future. This can
 * only be used to show warnings specific to Safari.
 * @returns true if the browser is Safari, othwerwise false.
 */
export function isSafari() {
    // We check `vendor` on purpose even though it is deprecated. It is just an additional
    // assurance in case the information is available
    return (!navigator.vendor || navigator.vendor.indexOf('Apple') !== -1) &&
        /safari/i.exec(navigator.userAgent) &&
        !/CriOS|FxiOS|android|chrome/i.exec(navigator.userAgent);
}
