// Utils
import { groupBy, map, uniq, isString, isObject } from 'lodash';
import { now, formatEventDates, toMomentDate } from '@/libs/utils/time';
import { getEventAssetSrc } from '@/libs/utils/assets';

// Classes
import BaseService from './base-service';

// Constants
import { API_BASE_PATH } from '@/libs/utils/constants';


/**
 * User's session API endpoint
 * @constant {String} SESSION_API_ENDPOINT
 */
const SESSION_API_ENDPOINT = `${API_BASE_PATH}/webapp/session/{{branding}}`;

/**
 * User's invitations API endpoint
 * @constant {String} INVITATIONS_API_ENDPOINT
 */
const INVITATIONS_API_ENDPOINT = `${SESSION_API_ENDPOINT}/invitations`;

/**
 * User's status API endpoint
 * @constant {String} USER_STATUS_API_ENDPOINT
 */
const USER_STATUS_API_ENDPOINT = `${API_BASE_PATH}/user-registration-status/{{eventId}}`;

/**
 * Provides methods necessary for managing user's session
 *
 * @example
 *
 * import SessionService from 'libs/services/session';
 * ...
 * const session = new SessionService();
 */
export default class SessionService extends BaseService {

    /**
     * Logs in the user and loads its data.
     *
     * @param {string} branding the app name for which log in the user
     * @param {string} locale the user's locale
     * @param {string|string[]} [invitationToken] the token to use for logging the user in
     *
     * @returns {Promise<object>} the user's data
     */
    async login(branding, locale, invitationToken) {
        const url = this.buildUrl(INVITATIONS_API_ENDPOINT, { branding });
        const { data } = await this.post(url, { invitationToken, with_testing: true });

        return this.buildInvitations(data, locale);
    }

    /**
     * Use this method to clear session's data on the server.
     *
     * @param {string} branding the app name from which logging out the user;
     */
    logout(branding) {
        const url = this.buildUrl(SESSION_API_ENDPOINT, { branding });
        return this.delete(url);
    }

    /**
     * Gets the user registration status for the given event.
     *
     * @param {string} eventId the ID of the event
     * @param {string} invitationToken the invitation token for this event
     *
     * @returns {Promise<object>} the user's registration status
     */
    async getCurrentUser(eventId, invitationToken) {
        const url = this.buildUrl(USER_STATUS_API_ENDPOINT, { eventId });
        const { data } = await this.post(url, { invitationToken });
        if (data.user) {
            data.user.activationUrl = data.activationUrl;
            data.user._authenticated = data.authenticated;
            data.user._returningUser = data.returning_user;
            data.user.registeredSessions = data.registered_sessions;
        }
        return data;
    }

    /**
     * Given an HTTP response from invitations API endpoint this method builds
     * the invitations object grouped by time.
     *
     * @param  {Object} response the HTTP response from the invitations endpoint
     * @param {string} locale the user's locale
     *
     * @return {Object} containing grouped invitations
     *
     * @private
     */
    buildInvitations(response, locale) {
        // make invitations unique
        response.invitations.invitations = uniq(response.invitations.invitations);

        const invitations = {
            token: response.token,
            account: response.invitations.account,
            invites: map(response.invitations.invitations, invite =>
                this.decorateInvite(response.token, invite, locale)
            ),
            contentHubs: response.invitations.contentHubs.map(hub =>
                this.decorateContentHub(response.token, hub)
            ),
            registrations: response.invitations.registrations,
            webinars: response.invitations.webinars.map(webinar => {
                return {
                    ...webinar,
                    token: response.token
                };
            })
        };

        const NOW = now();

        // We group events in `future`, `current` and `past` categories starting
        // from current time.
        invitations.byGroup = groupBy(invitations.invites, invite => {
            if (invite.stage !== 'production') {
                return 'testing';
            }

            const startDate = toMomentDate(invite.start_date * 1000);
            const endDate = toMomentDate(invite.end_date * 1000);

            if (startDate.isAfter(NOW) && endDate.isAfter(NOW)) {
                return 'future';
            }

            return startDate.isAfter(NOW) ||
                (startDate.isBefore(NOW) && endDate.isAfter(NOW))
                ? 'current'
                : 'past';
        });

        console.info('[Invite] Events list:', invitations);

        return invitations;
    }

    /**
     * Given a token and an event object this method decorates that object
     * with data used for UI purpose and some internal application data.
     *
     * @param {String} token   the invitation token
     * @param {Object} invite  the event object
     * @param {string} locale the user's locale
     *
     * @return {Object} an event object decorated with UI elements and application data
     *
     * @private
     */
    decorateInvite(token, invite, locale) {
        invite.venue = invite.venue?.city;
        invite.dates = formatEventDates(locale, invite.start_date, invite.end_date);
        invite.iconUrl = getEventAssetSrc('event-icon', invite);

        invite.token = token;

        if (isString(invite.title)) {
            invite.titleLabel = invite.title;
        } else if (isObject(invite.title)) {
            invite.titleLabel = invite.title.en || Object.values(invite.title)[0] || invite.name;
        } else {
            invite.titleLabel = invite.name;
        }

        return invite;
    }

    /**
     * Decorates content hub with token and iconUrl
     *
     * @param {String} token the invitation token
     * @param {Object} contentHub the content hub object
     *
     * @return {Object} an content hub object decorated with UI elements and application data
     *
     * @private
     */
    decorateContentHub(token, contentHub) {
        contentHub.token = token;
        contentHub.iconUrl = getEventAssetSrc('event-icon', contentHub);
        return contentHub;
    }
}
