<template lang="pug">
.invitations
    header.main
        .container
            account(:email="session.account" :token="session.token" :pages="branding.pages")
            .search-toggler(@click="sxs = !sxs")
                i.ic-search

            h1 {{$t('events.title')}}

            .search-bar(:class="{'in': sxs}")
                .form-group
                    input.form-control(
                        type="search"
                        v-model="query"
                        :placeholder="$t('events.search_placeholder')"
                        @keyup="filter"
                    )
                    i.ic-search(v-if="query.length == 0")
                    .clear(@click="clearFilter" :class="{'in': query.length != 0}") &times;

            ul.tabs(v-if="showTabs")
                li.pill(@click="toggleTab('all')" :class="{'active': pills.all}") {{$t('events.all_pill')}}
                li.pill(v-if="testingInvitations" @click="toggleTab('testing')" :class="{'active': !pills.all && pills.testing}") {{$t('events.testing_pill')}}
                li.pill(v-if="currentInvitations" @click="toggleTab('current')" :class="{'active': !pills.all && pills.current}") {{$t('events.ongoing_pill')}}
                li.pill(v-if="futureInvitations" @click="toggleTab('future')" :class="{'active': !pills.all && pills.future}") {{$t('events.future_pill')}}
                li.pill(v-if="pastInvitations" @click="toggleTab('past')" :class="{'active': !pills.all && pills.past}") {{$t('events.past_pill')}}

    .container
        section
            .empty(v-if="isEmpty")
                empty-event-list

            invitations-list(
                v-show="pills.all || pills.testing"
                :title="$t('events.testing_title')"
                :invitations="testingInvitations"
                :activate="activate"
                context="testing"
            )

            invitations-list(
                v-show="pills.all || pills.future"
                :title="$t('events.future_title')"
                :invitations="futureInvitations"
                :activate="activate"
                context="future"
            )

            invitations-list(
                v-show="pills.all || pills.current"
                :title="$t('events.ongoing_title')"
                :invitations="currentInvitations"
                :activate="activate"
            )

            invitations-list(
                v-show="pills.all || pills.past"
                :title="$t('events.past_title')"
                :invitations="pastInvitations"
                :activate="activate"
                context="past"
            )

            invitations-list(
                :title="$t('events.content_hubs_title')"
                :invitations="contentHubs"
                :activate="activate"
                context="content-hub"
            )
</template>
<script>
import Account from '@/components/Account.vue';
import Legals from '@/components/Legals.vue';
import InvitationsList from '@/components/InvitationsList.vue';
import EmptyEventList from '@/components/EmptyEventList.vue';

/**
 * To get to this page properly use the link provided by the email, I.E.
 *
 * https://install.events/invitations?token=<verylongtoken>&app_id=webapp
 *
 * and replace the address with your local environment:
 *
 * http://mymachine/tokens/invitations/generate?token=<verylongtoken>&app_id=webapp
 */

export default {
    name: 'Invitations',

    components: { Account, InvitationsList, EmptyEventList },

    props: {
        session: {
            type: Object,
            required: true
        },

        branding: {
            type: Object,
            required: true
        }
    },

    data() {
        return {
            activating: false,
            invitations: [],
            currentInvitations: [],
            pastInvitations: [],
            futureInvitations: [],
            testingInvitations: [],
            contentHubs: [],
            error: null,
            showTabs: false,
            query: '',
            sxs: false,
            pills: {
                future: true,
                current: true,
                past: true,
                all: true
            },
            isEmpty: false,
        };
    },

    watch: {
        error(error) {
            throw new Error(error);
        }
    },

    created() {
        const invitations = this.session;

        if (!invitations.account) {
            return this.$router.push({
                name: 'login',
                params: {
                    branding: this.$route.params.branding
                }
            });
        }

        this.$root.$on('activation:error', reason => {
            this.activationError(reason);
        });

        this.currentInvitations = invitations.byGroup.current;
        this.pastInvitations = invitations.byGroup.past;
        this.futureInvitations = invitations.byGroup.future;
        this.testingInvitations = invitations.byGroup.testing;
        this.contentHubs = invitations.contentHubs;

        this.isEmpty = !Object.values(invitations.byGroup || {}).some(events => events && events.length);

        const hasInvites = !!this.session.invites.length;
        const hasContentHubs = !!this.session.contentHubs.length;

        if (!hasInvites && !hasContentHubs) {
            console.info('[Invitations] No invites...');
            this.title = 'login.no_invites';
        }

        let nbTabs = 0;
        [
            this.currentInvitations,
            this.pastInvitations,
            this.futureInvitations,
            this.testingInvitations
        ].forEach(i => {
            if (i !== undefined) {
                nbTabs++;
            }
        });

        this.showTabs = nbTabs > 1;

        if (this.session.invites.length === 1 && !hasContentHubs) {
            this.activate(this.session.invites[0]);
        }

        if (this.session.contentHubs.length === 1 && !hasInvites) {
            this.activate(this.session.contentHubs[0]);
        }

        const {event, redirect} = this.getRedirect();
        if (event && redirect){
            this.activate(event, redirect);
        } else if (redirect) {
            this.$services.routing.redirect(redirect);
        }
    },

    methods: {
        activationError(reason) {
            this.$modal.hide('legals');
            this.activating = false;
            this.error = reason;
        },

        getRedirect() {
            const redirect = this.$route.query.redirect;
            if (!redirect) {
                return {};
            }
            // redirect should something like /{eid}{path} or /content-hub/{eid}{path} or /{reg_page}{path}
            const match = redirect.match(/\/(content-hub\/)?([^/]+)(\/.*)?/);
            if (!match){
                return {};
            }
            const [, isContentHub, base, path] = match;

            if (isContentHub){
                // case 1: redirect to a content-hub, in this case the redirect is {path}
                const hub = this.session.contentHubs.find(event => event.eid === base);
                if (hub) {
                    // for content hub, the redirect should NOT include the EID
                    return {event: hub, redirect: path};
                }
                return {};
            }

            // case 2: redirect to a workspace, in this case the redirect is /{base}{path} or {path}
            // (as there is some magic in the event that remove eid from the path if present)
            const event = this.session.invites.concat(this.session.webinars).find(event => event.eid === base);
            if (event) {
                return {event, redirect: path};
            }

            // case 3: redirect to a registration page, in this case there is no event, just a path
            const reg = (this.session.registrations || []).find(reg => reg.pathName === base);
            if (reg) {
                const url = `/login/${this.$route.params.branding}${redirect}`;
                return {redirect: url};
            }
            return {};
        },

        async goToEvent(invite, redirect) {
            if (this.activating) return;
            this.activating = true;

            try {
                const url = await this.$services.event.getInvitationUrl(this.$route.params.branding, invite);
                if (url) {
                    this.$services.routing.redirect(url, {redirect});
                    return;
                }

                throw 'login.error-online-not-available';

            } catch (error) {
                this.activationError(error);
            }
        },

        showLegalModal(invite, requirements, redirect) {
            this.$modal.show(
                Legals,
                {
                    invite,
                    requirements,
                    redirect
                },
                {
                    name: 'legals',
                    adaptive: true,
                    height: 'auto',
                    maxWidth: 480,
                    width: '90%'
                }
            );
        },

        activate(invite, redirect) {
            const shortcode = this.$services.event.getShortCode(invite);
            this.$brand.getLegalRequirements(shortcode).then(requirements => {
                if (!requirements || requirements.filter(requirement => requirement.label).length === 0) {
                    return this.goToEvent(invite, redirect);
                }

                this.showLegalModal(invite, requirements, redirect);
            }, this.activationError).finally(() => {
                this.activating = false;
            });
        },

        toggleTab(pill) {
            let switcher = false;
            if (pill === 'all') {
                switcher = true;
            }

            Object.keys(this.pills).forEach(p => {
                this.pills[p] = switcher;
            });

            this.pills[pill] = true;
        },

        filter() {
            if (this.query.length !== 0) {
                this.storeOriginalValues();
            } else {
                this.clearFilter();
            }

            if (this.query.length >= 2) {
                this.currentInvitations = this.applyFilter(
                    this.originalCurrent
                );
                this.pastInvitations = this.applyFilter(this.originalFuture);
                this.futureInvitations = this.applyFilter(this.originalPast);
                this.testingInvitations = this.applyFilter(
                    this.originalTesting
                );
            }
        },

        applyFilter(set) {
            if (!set) {
                return;
            }
            let newSet = [];

            set.forEach(i => {
                if (
                    i.name.toLowerCase().indexOf(this.query.toLowerCase()) !==
                    -1
                ) {
                    newSet.push(i);
                }
            });

            return newSet;
        },

        clearFilter() {
            this.query = '';
            this.restoreOriginalValues();
        },

        storeOriginalValues() {
            if (!this.stored) {
                this.originalCurrent = this.currentInvitations;
                this.originalFuture = this.pastInvitations;
                this.originalPast = this.futureInvitations;
                this.originalTesting = this.testingInvitations;
            }
            this.stored = true;
        },

        restoreOriginalValues() {
            if (this.stored) {
                this.currentInvitations = this.originalCurrent;
                this.pastInvitations = this.originalFuture;
                this.futureInvitations = this.originalPast;
                this.testingInvitations = this.originalTesting;
            }
            this.stored = false;
        }
    },

    metaInfo() {
        return {
            title: this.$i18n.t('events.title').toString(),
            style: [
                {
                    cssText: 'body{background-color:#f3f3f3;}',
                    type: 'text/css'
                }
            ]
        };
    }
};

</script>
