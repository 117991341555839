/**
 * This file is used to configure the router behaviour.
 * We map a component to each route.
 *
 * A normal navigation will follow this pattern:
 *
 * 1. Welcome
 * 2. Login
 * 3. Invitation
 * 4. Webapp
 *
 * See each component for more informations.
 */

import VueRouter from 'vue-router';

// Pages
import Home from '@/pages/Home.vue';
import Login from '@/pages/Login.vue';
import Password from '@/pages/Password.vue';
import Invitations from '@/pages/Invitations.vue';
import Registration from '@/pages/Registration.vue';

// Constant
import { BRANDING_COOKIE_NAME, DEFAULT_APP_BRANDING } from '@/libs/utils/constants';

/**
 * Detects the branding from cookies or provides the default one
 *
 * @param {import('vue-router').Route} to the route to scan for the cookie
 *
 * @returns {string} the detected branding or the default one
 *
 * @private
 */
function getBranding(to) {
    return to.query.branding || window.$cookies.get(BRANDING_COOKIE_NAME) || DEFAULT_APP_BRANDING;
}

/**
 * Builds the route with the given name and adds the detected branding
 *
 * @param {import('vue-router').Route} to the route the user is requesting
 * @param {string} name the name of the route
 *
 * @returns {object} the redirect route object with the branding param
 *
 * @private
 */
function buildBrandedRoute(to, name) {
    return { name, params: { branding: getBranding(to) } };
}

export const routes = [
    // `root` and `welcome` pages are managed by `Home` component.
    { name: 'root', path: '/', redirect: '/welcome' },

    { name: 'welcome-nobrand', path: '/welcome', redirect: to => buildBrandedRoute(to, 'welcome') },

    { name: 'welcome', path: '/welcome/:branding', component: Home },

    // `login` and "no token" `invitations` are managed by `Login` component.
    {
        name: 'login-nobrand',
        path: '/login',
        redirect: to => buildBrandedRoute(to, to.query.install_instructions ? 'welcome' : 'login')
    },

    { name: 'login', path: '/login/:branding', component: Login },

    // Event's registration page
    { name: 'registration', path: '/login/:branding/:id', component: Registration, meta: { noTheme: true, subpage: 'Landing' } },

    // Event's embedded registration page
    { name: 'registration-embed', path: '/embed/login/:branding/:id', component: Registration, meta: { noTheme: true, subpage: 'LandingEmbed', embed: true } },

    // Event's registration page for entering pin code to access the registration form
    { name: 'registration-pin', path: '/login/:branding/:id/pin', component: Registration, meta: { noTheme: true, subpage: 'EnterPin' } },

    // Event's embedded registration page for entering pin code to access the registration form
    { name: 'registration-pin-embed', path: '/embed/login/:branding/:id/pin', component: Registration, meta: { noTheme: true, subpage: 'EnterPinEmbed' } },

    // Event's registration form page
    { name: 'registration-form', path: '/login/:branding/:id/registration', component: Registration, meta: { noTheme: true, subpage: 'SignUp' } },

    // Event's embedded registration form page
    { name: 'registration-form-embed', path: '/embed/login/:branding/:id/registration', component: Registration, meta: { noTheme: true, subpage: 'SignUp', embed: true } },

    // Event's registration end of funnel
    { name: 'registration-end', path: '/login/:branding/:id/:end/:emailSent', component: Registration, meta: { noTheme: true, subpage: 'End' } },

    // Event's embed registration end of funnel
    { name: 'registration-end-embed', path: '/embed/login/:branding/:id/:end/:emailSent', component: Registration, meta: { noTheme: true, subpage: 'End', embed: true } },

    // Event's registration summary page
    { name: 'registration-summary', path: '/login/:branding/:id/summary', component: Registration, meta: { noTheme: true, subpage: 'Summary' } },

    // If all parameters are valid `invitation` page is managed by
    // `Invitations` component.
    { name: 'invitations-nobrand', path: '/invitations', redirect: to => buildBrandedRoute(to, 'invitations') },

    {
        name: 'invitations',
        path: '/invitations/:branding',
        component: Invitations
    },

    // Passwords

    { name: 'pass-nobrand', path: '/password', redirect: to => buildBrandedRoute(to, 'changepass') },

    // This is a backward compatibility route that redirects to the
    // `Password` component, which manage the account password changes.
    // NOTE: we forward to this path from the `Login` component.
    {
        name: 'legacypass',
        path: '/password/:branding/:token/:scheme',
        redirect: () => {
            return '/password/:branding/:token';
        }
    },

    {
        name: 'pass-change',
        path: '/password/:branding/:token',
        component: Password
    },

    {
        name: 'changepass',
        path: '/password/:branding',
        component: Password
    },

    // Not found catch all
    { path: '*', redirect: '/' },
];

/**
 * Creates the router for the main vue instance.
 *
 * @param {import('vue').VueConstructor} Vue the vue instance
 *
 * @returns {import('vue-router').default} the vue router instance
 *
 * @private
 */
export function createRouter(Vue) {
    // Install the router
    Vue.use(VueRouter);

    // Creates the router instance
    const router = new VueRouter({
        base: '/',
        mode: 'history',
        routes
    });

    router.beforeEach((to, from, next) => {
        // If `branding` is passed as a query parameter,
        // we substitute the route branding parameter
        if (to.query.branding) {
            console.info('[Router] Branding query param detected', to.query.branding);
            const search = to.query.branding;
            const branding = Array.isArray(search) ? search[0] : search;
            to.params.branding = branding;

            delete to.query.branding;
            return next(to);
        }

        next();
    });

    return router;
}
