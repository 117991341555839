<template lang="pug">
.landing-embed
    main
        .container
            registration-card(:config="config" :session="session" :embed="true" :event-title="eventTitle")
            welcome-embed-card(:config="config")
</template>
<script>

// Components
import RegistrationCard from '@/components/registration/RegistrationCard.vue';
import WelcomeEmbedCard from '@/components/registration/WelcomeEmbedCard.vue';

export default {
    name: 'LandingEmbed',

    components: {
        RegistrationCard,
        WelcomeEmbedCard
    },

    props: {
        config: {
            type: Object,
            required: true
        },

        session: {
            type: Object,
            required: true
        },

        eventTitle: {
            type: String,
        }
    },

    data() {
        return {};
    },
};
</script>
